@mixin theflexstyle($direction, $justifyContent, $alignItems, $wrap) {
  display: flex;
  flex-direction: $direction ;
  justify-content: $justifyContent ;
  align-items: $alignItems ;
  flex-wrap: $wrap ;
}

.newProfileContainer {
  width: 100%;
  height: 100%;

  .contentcontainer {
    padding: 2rem;
  }

  .ant-input-affix-wrapper {
    height: 48px;
  }

  .upload_profile_pic {
    display: none;
  }

  .edit_holder {
    position: absolute;
    top: 25px;
    right: 25px;

    span {
      color: #4389fa;
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .profile_image_conatiner {
    cursor: "pointer" !important;

    .edit_icon {
      cursor: "pointer";
      position: absolute;
      z-index: 2;
      margin-left: 46px;
      margin-top: -24px;
    }
  }

  .topSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 40px 60px;

    .horizontalCenter1 {
      @include theflexstyle(row, center, inherit, nowrap)
    }

    .profileinfocontainer {
      margin-left: 1.5rem;
    }

    .profileName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: 0.01em;
      color: #261c4b;
      margin: 0;
    }

    .profileSubName {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #261c4b;
      margin: 0;
    }

    .emailtext {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #261c4b;
      margin: 0 18px;

      p {
        background: #fff2f2;
        border-radius: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #ed0606;
        padding: 5px;
        cursor: pointer;
      }
    }
  }

  .horizontalCenter {
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;

    .col1 {
      padding-right: 1rem;

    }
  }

  .ant-input-affix-wrapper>input.ant-input {
    background: none;
  }

  .linkBtn {
    text-align: center;
    background: rgba(67, 137, 250, 0.12);
    border-radius: 8px;
    width: 102px;
    height: 36px;
    margin: 0;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .borderedBtn {
    text-align: center;
    border: 1px solid #4389fa;
    border-radius: 8px;
    width: 102px;
    height: 36px;
    margin: 0;
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .urltext {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #4267b2;
    margin: 0;
    cursor: pointer;
  }

  .subTitlesText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #261c4b;
    margin: 0;
  }

  .subsCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #4389FA;
    // background: linear-gradient(180.15deg,
    //     #4389fa 0.13%,
    //     rgba(67, 137, 250, 0.53) 124.95%);
    //box-shadow: 0px 0px 3px rgba(67, 137, 250, 0.15);
    border-radius: 20px;
    padding: 24px 30px;
    margin-right: 30px;
  }

  .currentplantext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin: 0;
  }

  .freetext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin: 16px 0;
  }

  .subsInfotext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #261c4b;
    margin-right: 60px;
    margin-bottom: 24px;
  }

  .cardtextinput {
    width: 100%;
    padding: 12px 16px 11px;
    background: #f8fbff;
    border: 1px solid #d4dbfa;
    box-sizing: border-box;
    border-radius: 8px;
    align-self: stretch;
    margin-left: 24px;
  }

  .bigcard {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding-bottom: 24px;
    margin-top: 16px;
    height: 90%;

    .cardsec {
      margin: 8px 40px;

      .subcriptioncard {
        @include theflexstyle(row, space-between, flex-start, nowrap)
      }
    }

    .headersec {
      border-bottom: 1.5px solid #f2f4f7;
      margin-bottom: 24px;
      padding: 24px 40px;
    }
  }

  .rowdiv {
    display: flex;
    width: 75%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
  }

  .textInput {
    width: -webkit-fill-available;
    padding: 12px 16px 11px;
    height: 46px;
    background: #f8fbff;
    border: 1px solid #d4dbfa;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .textInput:focus {
    outline: none !important;
    border: 2px solid #d4dbfa;
  }

  .inputstyle {
    width: 75%;
    padding: 12px 16px 11px;
    background: #f8fbff;
    border: 1px solid #d4dbfa;
    box-sizing: border-box;
    border-radius: 8px;
    align-self: stretch;
    flex-grow: 0;
  }

  .compsatitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #261c4b;
    margin-left: 24px;
    ;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
    margin-top: -4px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .fieldTitles {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #423f3f;
    margin-top: 10px;
  }

  .check_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 161px;
    height: 42px;
    margin-left: 40px;
    background: #4389fa;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    cursor: pointer;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #fefefe;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 8px;
    }
  }

  .error_fields {
    color: #ff4d4f;
    height: 22px;
  }

  .subcontainer {
    @include theflexstyle(row, space-between, center, nowrap);
    margin-bottom: 24px;
  }

  .responsiveedit {
    display: none;
  }
}

@media screen and (max-width:768px) {
  .newProfileContainer {
    .profile_image_conatiner {
      // position: absolute;
      // top:10px;
      margin-top: -50px;
    }

    .contentcontainer {
      padding: 60px 24px 24px;
    }

    .horizontalCenter {
      @include theflexstyle(row, start, flex-start, nowrap);
    }

    .topSection {
      padding: 1rem;
      flex-direction: column;

      .emailtext {
        font-size: 12px;
      }

      .horizontalCenter1 {
        @include theflexstyle(column, center, center, nowrap);
        margin-bottom: 1rem;
      }

      .profileinfocontainer {
        margin: 0;
      }

      .profileName {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
        line-height: 1.5rem;
      }

      .profileSubName {
        font-size: 12px;
        color: #261c4b;
        opacity: 0.54;
      }
      // .editimage{
      //   display: none;
      // }
    }

    .subsCard {
      margin-right: 0;
      width: 100%;
      padding: 24px 32px;
      @include theflexstyle(column, flex-start, flex-start, nowrap)
    }

    .bigcard {
      height: auto;

      .cardsec {
        margin: 0;
      }

      .headersec {
        @include theflexstyle(row, flex-start, center, nowrap);

        margin: 1rem auto;
        padding: 1.5rem;

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    .subsInfotext {
      margin-top: 1.5rem;
      width: 100%;
      text-align: left;
      margin-right: 0;
      padding: 0;
      font-weight: 400;
    }

    .bigcard {
      .cardsec {
        // margin:0.5rem;
        padding: 0 24px;

        .subcriptioncard {
          @include theflexstyle(column, space-between, center, nowrap);
        }
      }
    }

    .contentContainer {
      .col1 {
        padding: 0;
      }
    }

    .compsatitle {
      margin-left: 11px;
      font-size: 1rem;
      line-height: normal;
    }

    .borderedBtn {
      color: #4389fa;
      font-size: 14px;
      font-weight: 400;
      border-radius: 12px;
    }

    .subTitlesText {
      font-size: 13px;
      font-weight: 400;
      line-height: normal;
    }

    .updatedText {
      font-family: 'Poppins';
      font-style: italic;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.01em;
      color: #4389FA;
    }

    .subcontainer {
      @include theflexstyle(row, end, center, wrap);

    }

    .change1 {
      margin-top: 8px;
    }

    .responsiveedit {
      display: block;
      position: absolute;
      right: 40px;
      top: 75px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      text-align: right;
      letter-spacing: 0.01em;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #261C4B;
      opacity: 0.54;

      img{
        width: 16px;
        height: 16px;
      }
    }
  }


}