@import "../../styles/variables.scss";
@import "../../styles/responsivestyles.scss";

.fontfamily {
  font-family: "Poppins";
}

$breakpoints: (
  mobile: 599px,
);

@mixin mobile-only {
  @media screen and (max-width: map-get($breakpoints, mobile)) {
    @content;
  }
}

@mixin flexsection($direction, $alignItems, $justcontent: "inherit") {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justcontent;
}

.ResumeVault-container {
  width: 100%;
  height: 100%;

  .white_bg,
  .red_bg {
    background: $whiteColor;
    border-radius: 6px;
    padding: 5px 10px;
    color: rgba(113, 108, 139, 0.64);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    @media screen and (min-width: 300px) and (max-width: 580px) {
      background: inherit;
    }
  }

  .guideContainer {
    background: rgba(0, 0, 0, 0.36);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 1;
    text-align: start;
    display: flex;
    justify-content: end;

    .guide {
      width: 20%;
      text-align: center;
      @include flexsection(column, center, center);
      margin: -11% 15.5% 0% 0%;

      p {
        color: $whiteColor;
      }

      div {
        border: 1px solid $whiteColor;
        border-radius: 12px;
        padding: 10px 15px;
        font-weight: 500;
        font-size: 16px;
        color: $whiteColor;
        width: 30%;
        cursor: pointer;
      }
    }
  }

  .ResumwVault-body {
    width: 100%;
    padding: 30px;
    color: $primaryFontColor;
    .resumeVault-cards-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .resumeVault-cards {
        width: 73%;
      }
      .resumeVault-Suggestion {
        width: 25%;
      }
    }

    @media screen and (min-width: 300px) and (max-width: 580px) {
      padding: 1.75rem 1.5rem;
    }

    .ResumeVault-body-header {
      @include flexsection(column, flex-start, space-between);
      margin-bottom: 2rem;
      @media screen and (min-width: 300px) and (max-width: 580px) {
        margin-bottom: 1.5rem;
      }
    }

    .ResumeVault-body-header {
      background-color: white;
      border-radius: 16px;
      margin-bottom: 14px;
      padding: 12px;
      margin-top: 0px;
    }

    .vault-title {
      @extend .fontfamily;
      font-style: normal;
      font-weight: 500;
      @extend .fontfamily;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: 0.01em;
      color: rgb(67, 137, 250);

      @media screen and (min-width: 300px) and (max-width: 580px) {
        font-size: 1.2rem;
      }
    }

    .vault-subtitle {
      @extend .fontfamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: $secondaryFontColor;

      @media screen and (min-width: 300px) and (max-width: 580px) {
        color: $primaryFontColor;
        width: 100%;
        opacity: 0.54;
        font-size: 14px;
      }
    }

    .vault-findmore {
      color: $primaryColor;
      cursor: pointer;
      font-size: 16px;

      @media screen and (min-width: 300px) and (max-width: 580px) {
        font-size: 14px;
      }
    }

    .vault_card_container {
      padding: 12px;
      @media screen and (max-width: 400px) {
        padding: 12px 0px 0px;
      }
      .vault_card {
        background: $whiteColor;
        // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
        overflow: hidden;

        .vault_card_header {
          padding: 20px;
          background: #ffff;

          .titleCard {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .documentText {
              background: $whiteColor;
              border-radius: 100px;
              padding: 15px;
              margin-right: 10px;

              @media screen and (min-width: 300px) and (max-width: 580px) {
                display: none;
              }
            }

            .resume_name {
              font-weight: 500;
              font-size: 20px;
              color: #261c4b;
              display: flex;
              align-items: center;
              .name {
                max-width: 170px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              @media screen and (min-width: 300px) and (max-width: 580px) {
                font-size: 1.25rem;
              }
            }
            .last_updated {
              font-weight: 700;
              font-size: 16px;
            }
          }
          .resumelast-edited__date {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 5px;
            padding-top: 10px;
            margin-bottom: 0px;
            border-top: 1px solid rgba(38, 28, 75, 0.1);
            font-size: 14px;
            min-height: 33px;

            @extend .vault-subtitle;

            @media screen and (min-width: 300px) and (max-width: 580px) {
              margin-bottom: 0.5rem;
            }
          }
          .edit_btn {
            font-weight: 500;
            font-size: 16px;
            padding: 0px 15px;

            button {
              background: $primaryColor;
              border-radius: 10px;
              border: none;
              color: $whiteColor;
              padding: 5px 10px;
              cursor: pointer;
              font-size: 14px;
            }
          }
          .resume_controles {
            margin-top: 5px;
            .ant-col {
              padding: 5px !important;
            }

            .red_bg {
              color: $red_bg;
            }
          }
        }

        .vault_card_body {
          padding: 10px 20px;
          color: $primaryColor;
          width: 100%;
          font-weight: 500;
          font-size: 16px;
          background: $whiteColor;

          span {
            color: rgba(38, 28, 75, 0.84);
            font-weight: 400;
            font-size: 16px;
          }
        }
      }
    }

    .devider {
      border: 1px solid rgba(38, 28, 75, 0.1);
      margin: 20px 15px;
      width: 100%;
      @media screen and (max-width: 400px) {
        margin: 12px 0px 0px;
      }
    }
  }

  .redTxt {
    color: $redTxt;
    font-weight: 400;
    font-size: 16px;
    padding-left: 15px;
  }
}

.resume-control__optionName {
  display: block;
}

.ResumevalutOptimizebutton {
  p {
    margin-bottom: 0;
  }
  @include flexsection(column, center);

  @media screen and (min-width: 300px) and (max-width: 580px) {
    @include flexsection(row, center);
    p {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 300px) and (max-width: 580px) {
  .resume-control__optionName {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .ResumeVault-container
    .ResumwVault-body
    .vault_card
    .vault_card_header
    .titleCard {
    flex-direction: column;
  }
  .resumeVault-cards-container {
    width: 100%;
    flex-direction: column-reverse;
    .resumeVault-cards {
      width: 100% !important;
      margin: 0px !important;
    }
    .resumeVault-Suggestion {
      width: 100% !important;
    }
  }
}
