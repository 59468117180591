@import "../../styles/responsivestyles.scss";
@import "../../styles/base.scss";

.jobMatchContainer {
  width: 100%;
  height: 100%;
  padding: 32px;

  .jobMactFormTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: white;
    border-radius: 16px;
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .check_tracker_btn_container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .check_tracker_btn {
        margin-left: 10px;
        margin-top: 0px;
      }
    }
  }

  .contentHeaderContainer {
    width: 100%;
    padding: 32px;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    margin-top: 20px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #4389fa;
    }

    .detailsButton {
      font-size: 16px;
      color: #4389fa;
      cursor: pointer;
    }
  }

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    gap: 16px;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
  }

  .textInput {
    width: -webkit-fill-available;
    padding: 12px 16px 11px;
    height: 46px;
    background: #f8fbff;
    border: 1px solid #d4dbfa;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .textInput:focus {
    outline: none !important;
    border: 2px solid #d4dbfa;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
    // height: 48px;
    // padding-top: 10px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .fieldTitles {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #423f3f;
    margin-top: 10px;
  }

  .check_tracker_btn,
  .check_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 161px;
    height: 42px;
    margin-top: 16px;
    background: #4389fa;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    cursor: pointer;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #fefefe;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 8px;
    }
  }

  .check_tracker_btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .error_fields {
    color: #ff4d4f;
    height: 22px;
  }

  .selectResume {
    width: 100%;
    display: flex;
    align-items: center;

    p {
      margin: 0px;
      padding-right: 6px;
      font-weight: 400;
      font-size: 16px;
    }

    .customeselectResume {
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid rgb(222, 229, 255);
      border-radius: 8px;
      height: 50px;
      color: white;

      .ant-select-arrow {
        color: rgba(0, 0, 0, 0.25);
      }

      .ant-select-selection-item {
        color: black;
      }
    }
  }
}

.personalDashboard_container {
  .title {
    font-weight: 600;
    font-size: 20px;
    color: #261c4b;
    //margin-bottom: 10px;
  }

  .faverates {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .resumeVaults {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
    margin-top: 10px;

    .resumeVault {
      border-radius: 12px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .documentText {
        background: #ffffff;
        border-radius: 100px;
        padding: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 25px;
        }
      }

      .resume_name {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;

        color: #261c4b;
      }

      .controls {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: 50%;

        .white_bg {
          margin: 0px 10px;
          cursor: pointer;
        }
      }
    }
  }

  .skills_wrapper {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 10px;
    margin-top: 10px;

    .skillsContainer {
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;

        color: #261c4b;
      }

      .blue_bg {
        //background: #eef3fe;
        border-radius: 12px;
        padding: 15px;
        //max-height: 130px;
        overflow: auto;
      }
    }
  }

  .tableContainer {
    margin-bottom: 30px;

    table {
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      width: 100%;
    }

    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;

      /* Tex/Dark */

      color: #261c4b;
    }

    td,
    th {
      // border: 1px solid #ffffff;
      text-align: left;
    }

    tr:nth-child(even) {
      background-color: #fafcff;
    }

    .table_footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 15px;
      border-top: 1px solid #f2f4f7;
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 12px 12px;

      p {
        margin: 0px;
        font-size: 12px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .add-date-button {
    background: #f9fcff;
    border: 1px solid rgba(67, 137, 250, 0.2);
    border-radius: 10px;
    color: #261c4b;
    width: 100%;
    cursor: pointer;
    padding: 7px 20px;
  }

  .companyName:hover {
    background: gainsboro;
    opacity: 0.5;
    cursor: pointer;
  }
}

.resume_optimizer_body_right {
  // background: #ffffff;
  // box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.05);
  // border-radius: 16px;
  // padding: 15px;
  .title {
    padding: 15px 0px;

    img {
      margin-right: 20px;
    }

    span {
      font-weight: 500;
      font-size: 24px;
    }
  }

  .title-label {
    font-weight: 700;
    font-size: 16px;
    color: #261c4b;
  }

  .default_content {
    color: #606060;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    text-align: center;
  }

  .employementSteps {
    .title {
      .subtitle {
        margin-left: 19%;
      }
    }

    .selected_step {
      color: #261c4b;

      .step,
      .label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }

      .desc {
        color: rgba(38, 28, 75, 0.84);
        font-size: 14px;
      }

      .nextStepButton {
        background: #4389fa;
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        border: none;
        color: #fefefe;
        margin-top: 15px;
        cursor: pointer;
      }
    }

    .unselected_step {
      color: rgba(38, 28, 75, 0.52);
      font-size: 16px;
    }

    .borderBottom {
      border: 0.5px solid rgba(38, 28, 75, 0.1);
      margin: 20px 0px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .jobMatchContainer {
    padding: 24px;
    .jobMactFormTitle {
      gap: 16px;
      font-size: 16px;
      flex-wrap: wrap;
      white-space: nowrap;
    }
    .contentContainer {
      padding: 16px;
      gap: 0;
    }
    .resume_optimizer_body_right {
      margin-top: 16px;
    }
    .selectResume {
      margin: 10px 0;
    }
  }
}

@include mobileProtriet {
}

@include tablet {
}
