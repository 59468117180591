@import "../../styles/base.scss";

.Common_input_container {
  min-width: 32%;
  .companyInput {
    background: transparent;
    width: 100%;
  }

  .inputWrapper {
    background: white;
    border: 1px solid $inputFieldBorder;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 50px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    &:hover {
      background: white;
      border: 1px solid $inputFieldBorder;
    }
    &:focus {
      outline: none;
    }
    input {
      border: none;
      height: auto;
    }
    p {
      margin: 0px;
      white-space: nowrap;
    }
  }
}
