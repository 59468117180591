.ant-card-body {
  padding: 0px;
}

.interestingReadsTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.01em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #4389fa;
  margin-bottom: 14px;
  background-color: white;
  border-radius: 10px;
  padding: 12px;
  width: 100%;
}

.cardSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 316px;
  background: #ffffff;
  // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #000000;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .subHead {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #4389fa;
    margin-bottom: 12px;
  }

  .desc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #000000;
  }

  .readMore {
    padding: 8px 16px;
    gap: 8px;
    width: fit-content;
    height: 32px;
    background: #ffffff;
    border: 1px solid #4389fa;
    border-radius: 4px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #4389fa;
    cursor: pointer;
  }
}
@media (max-width: 48em) {
  .jBodSf > p {
    overflow: auto !important;
    text-overflow: inherit !important;
    white-space: inherit !important;
  }
}
