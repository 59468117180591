.personality_test_body {
  padding: 3% 5%;
  .title {
    font-size: 20px;
    font-weight: 600;
    // span {
    //   font-size: 15px;
    //   font-weight: 500;
    // }
  }

  .perCardDiv {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding-top: 108px;
    padding-bottom: 108px;

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #261c4b;
    }

    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #261c4b;
      opacity: 0.64;
      margin-bottom: 0px;
      margin-top: 8px;
      max-width: 600px;
    }
  }

  .progressBar_holder {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .Questions_holder {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin: 0% 15%;
    padding: 4%;
    .question_holder {
      padding-bottom: 2%;
      .question {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .choices {
        .choice {
          font-size: 16px;
        }
      }
    }
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background: #4389fa;
        border-radius: 10px;
        border: none;
        color: white;
        padding: 10px 20px;
        margin: 5px;
        cursor: pointer;
      }
    }
  }

  // results
  .personalityTest_Results {
    padding: 0% 5%;
    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .results {
      .resultBig5 {
        border-radius: 16px;
        margin-top: 20px;
        padding: 5%;
        background: white;
        // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
        .result_title_big5 {
          font-weight: 600;
          font-size: 24px;
        }
        .resultBig5graph {
          border: 1px solid rgba(38, 28, 75, 0.2);
          border-radius: 16px;
          padding: 20px 45px;
          margin-top: 20px;
        }
      }
      .result {
        // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
        padding: 5%;
        margin-top: 30px;
        background: white;
        .tabs {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .tab {
            font-weight: 600;
            font-size: 20px;
            color: rgba(38, 28, 75, 0.64);
            cursor: pointer;
          }
          .avtiveTab {
            color: #ea9ec9;
            border-bottom: 2px solid #ea9ec9;
          }
        }
        .tab_content {
          padding-top: 20px;
          .tab_graph {
            background: #ffffff;
            border: 1px solid rgba(38, 28, 75, 0.2);
            border-radius: 16px;
            padding: 20px;
            .titleCard {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              color: #261c4b;
              .shortDescription {
                font-weight: 500;
                font-size: 20px;
                width: 75%;
              }
              .score,
              .high {
                background: #ffffff;
                border: 1px solid rgba(38, 28, 75, 0.2);
                border-radius: 8px;
                padding: 10px;
                font-weight: 500;
                font-size: 20px;
                span {
                  color: #e25151;
                }
              }
              .high {
                margin-left: 15px;
              }
            }
          }
          .subDescription {
            padding-top: 20px;
            .sub_result {
              padding: 15px 0px;
              .titleCard {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                .sub_result_title {
                  font-weight: 500;
                  font-size: 20px;
                  color: #261c4b;
                }
                .sub_subDetails {
                  margin-left: 10px;
                  font-weight: 400;
                  font-size: 16px;
                  color: rgba(38, 28, 75, 0.64);
                }
              }
              .sub_shortDescription {
                font-weight: 400;
                font-size: 16px;
                color: rgba(38, 28, 75, 0.64);
              }
              .devider {
                border: 1px solid rgba(38, 28, 75, 0.1);
              }
            }
          }
        }
      }

      // graph
      .customgraph_container {
        position: relative;
        .svg {
        }
        .customGraphs {
          display: flex;
          justify-content: space-between;
          padding: 56px;
          padding-left: 70px;
          position: absolute;
          width: 100%;
          top: -7px;
          left: 20px;
          .customGraph_wrap {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            .customGraph {
              height: 300px;
              background-color: #ea9ec91a;
              display: flex;
              align-items: end;
              .Graphcontent {
                border-radius: 15px;
                height: 50%;
                padding: 20px;
                background-color: #ea9ec9;
                font-weight: 600;
                font-size: 20px;
                color: white;
              }
            }
            .label {
              font-weight: 600;
              font-size: 14px;
              color: #261c4b;
              text-align: center;
              margin: 10px 0px 0px;
              max-width: 110px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
    .personality_test_body{
      .perCardDiv{
        padding-top: 50px;
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        .cardmainimage{
          width: 100%;
          margin:auto;
        }
        .title{
          font-size: 1.5rem;
          line-height: normal;
        }
        .subtitle{
          font-size: 0.875rem;
          line-height: normal;
          // width:;
        }
      }
      .Questions_holder{
        margin: 0rem;
      }
    }
}
