.jobMatchResultsContainer {
  .jobMactResultFormTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .subHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #1e1d1d;
      flex: none;
      order: 0;
      flex-grow: 1;
      margin: 0px;
    }
  }

  .jobMatchResultsSubContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 12px;
    width: 100%;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    .row {
      padding: 24px;
      .left {
        padding-right: 15px;
      }
      .right {
        padding-left: 15px;
      }
    }

    .verticalLine {
      border-right: 1.5px solid #6d6d6d;
      flex: none;
      align-self: stretch;
      flex-grow: 0;
      margin-right: 32px;
    }

    .subHeaderTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #261c4b;
      margin: 0px;
    }

    .subHeaderTitle2_container {
      display: flex;
      width: 100%;
      align-items: center;
      border-bottom: 4px solid rgb(233, 241, 255);
      padding: 24px;
      padding-top: 0;
      .subHeaderTitle2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #261c4b;
        margin: 0px 10px 0px 0px;
      }
    }
  }

  .jobfit_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h3 {
      border-bottom: 4px solid rgb(233, 241, 255);
      padding-bottom: 10px;
    }
    .left {
      width: 60%;
      height: 100%;
      border-radius: 16px;
      background: #ffff;
      border: 1px solid #8080802b;
      padding: 15px;
    }
    .right {
      width: 37%;
      height: 100%;
      border-radius: 16px;
      background: #ffff;
      border: 1px solid #8080802b;
      padding: 15px;
      span {
        font-weight: 500;
      }
    }
  }

  .improvement_container_outer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .improvement_container {
      width: 49%;
      .unbotSuggestion {
        h3 {
          border-bottom: 4px solid rgb(233, 241, 255);
          padding-bottom: 10px;
        }
        h4 {
          margin: 15px 0px;
        }
        height: 100%;
        width: 100%;
        border-radius: 16px;
        background: #ffff;
        border: 1px solid #8080802b;
        padding: 15px;
        .ButtonContainer {
          .editResumeBtn {
            margin-left: 15px;
          }
        }
      }
    }
  }

  .jobResultCard {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: #ffff;
    border: 1px solid #8080802b;

    .heading_container {
      padding: 12px;
      border-bottom: 4px solid rgb(233, 241, 255);
      .subHeaderTitle2 {
        font-size: 16px;
        font-weight: 600;
      }
      .heading_container_right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .cardContaent {
      border-radius: 16px;
      padding: 10px;
      margin-top: 20px;
      margin-bottom: 0px;
      height: 200px;
      overflow: auto;
      p {
        word-break: break-all;
      }
    }

    .smallCardContent {
      border-radius: 16px;
      padding: 10px;
      margin-top: 20px;
      margin-bottom: 0px;
      min-height: 44px;
    }
  }
  .jobSkillsResultCard {
    background: #ffff;
    border-radius: 16px;
    border: 1px solid #8080802b;
    .heading_container {
      padding: 12px;
      border-bottom: 4px solid rgb(233, 241, 255);
      .subHeaderTitle2 {
        font-size: 16px;
        font-weight: 600;
      }
      .heading_container_right {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .contentCardDescription {
    font-family: "Poppins";
    width: -webkit-fill-available;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    margin: 24px;
    border-radius: 16px;
    padding: 12px;
    border: 2px solid #e9f1ff;
  }

  .horiLine {
    border-bottom: 1.5px solid #606060;
    width: 100%;
    // height: 0.5px;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .ratingCardsCon {
    display: flex;
    // flex-direction: row;
    // justify-content: space-between;

    p {
      font-family: "Poppins";
      font-style: normal;
      // font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #606060;
      margin: 0px 20px;
    }
  }

  .fourCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    .cardItem {
      width: 49.5%;
      background-color: white;
      border-radius: 24px;
      margin-bottom: 15px;
      border: 1px solid #8080802b;
      border-radius: 16px;
      padding: 12px;
      .cardHeader {
        display: flex;
        align-items: center;
        border-bottom: 4px solid #e9f1ff;
        padding: 15px;
        .cardHeaderTitle {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;
          margin-bottom: 0;
        }
      }
      .cardContent {
        text-align: center;
        .flex_container {
          margin: 10px 0px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .left,
          .right {
            width: 50%;
          }
          .left {
            text-align: right;
            font-weight: 600;
          }
          .right {
            text-align: left;
            margin-left: 5px;
          }
        }
      }
      .cardFooter {
        padding: 15px;
        border-top: 1px solid rgba(128, 128, 128, 0.17);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .jobfit_container {
      justify-content: center;
      flex-direction: column;
      h3 {
      }
      .left {
        width: 100%;
        margin-bottom: 15px;
      }
      .right {
        width: 100%;
      }
    }
    .jobMatchResultsSubContainer {
      margin-top: 0px;

      .verticalLine {
      }

      .subHeaderTitle {
        font-size: 14px;
      }

      .subHeaderTitle2_container {
        border-bottom: 2px solid rgb(233, 241, 255);
        padding: 10px;
        .subHeaderTitle2 {
          font-size: 16px;
          line-height: 22px;
        }
      }
      .row {
        padding: 10px 0px;
        .left {
          padding-right: 0px;
          margin-bottom: 15px;
        }
        .right {
          padding-left: 0px;
        }
      }
    }
    .fourCardsContainer {
      .cardItem {
        width: 100%;
        .cardHeader {
          flex-wrap: wrap;
        }
      }
    }
    .improvement_container_outer {
      flex-direction: column;
      .improvement_container {
        width: 100%;
        margin-bottom: 15px;
        .unbotSuggestion {
          .ButtonContainer {
            .editResumeBtn {
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .improvement_container_outer {
      .improvement_container {
        .unbotSuggestion {
          .ButtonContainer {
            flex-direction: column;
            .editResumeBtn {
              margin: 0;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
