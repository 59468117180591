.offerEvaluatorContainer {
  width: 100%;
  height: 100%;

  .Common_input_container {
    //width: 80%;
    // background: #f9fcff;
    // border: 1px solid rgba(67, 137, 250, 0.4);
    border-radius: 8px;
    min-width: 32%;
    align-self: stretch;
    margin-left: 40px;

    .label {
      font-weight: 400;
      font-size: 10px;
      color: #000000;
    }
    .companyInput {
      border: none;
      background: transparent;
      width: 100%;
    }
    .companyInput.ant-select {
      border: none;
      background: transparent;
      width: 100%;
      .ant-select-selector {
        border: none;
        background: transparent;
        width: 100%;
        padding: 0px;
      }
    }
    input,
    textarea {
      &:focus {
        outline: none;
      }
    }
  }

  .offerTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
  }

  .cardstyle {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    display: flex;
    padding: 18px 40px;
    align-items: center;
    margin-top: 16px;

    .cardtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      color: #261c4b;
      margin: 0;
      padding-left: 24px;
    }

    .cardtextinput {
      width: 100%;
      padding: 12px 16px 11px;
      background: transparent;
      border: 1px solid rgb(222, 229, 255);
      box-sizing: border-box;
      border-radius: 8px;
      align-self: stretch;
      margin-left: 24px;
    }
  }
  .cardtextinput {
    width: 86%;
    padding: 12px 16px 11px;
    background: transparent;
    border: 1px solid rgb(222, 229, 255);
    box-sizing: border-box;
    border-radius: 8px;
    align-self: stretch;
    margin-left: 40px;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: none;
  }

  .bigcard {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding-bottom: 24px;
    margin-top: 16px;

    .cardsec {
      display: flex;
      flex-direction: row;
      margin: 8px 40px;
      align-items: center;
    }

    .headersec {
      padding: 24px 40px;
    }
  }

  .rowdiv {
    display: flex;
    width: 75%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  .formContainer {
    display: flex;
    flex-direction: column;
  }

  .textInput {
    width: -webkit-fill-available;
    padding: 12px 16px 11px;
    height: 46px;
    background: #f8fbff;
    border: 1px solid #d4dbfa;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .textInput:focus {
    outline: none !important;
    border: 2px solid #d4dbfa;
  }

  .inputstyle {
    width: 70%;
    padding: 12px 16px 11px;
    background: #f8fbff;
    border: 1px solid #d4dbfa;
    box-sizing: border-box;
    border-radius: 8px;
    align-self: stretch;
    flex-grow: 0;
  }

  .compsatitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #261c4b;
    margin: 0;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
    margin-top: -4px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .fieldTitles {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #423f3f;
    margin-top: 10px;
  }

  .check_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 161px;
    height: 42px;
    margin-left: 32px;
    background: #4389fa;
    border-radius: 10px;
    border: none;
    box-shadow: none;
    cursor: pointer;

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #fefefe;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 8px;
    }
  }

  .numbercircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4389fa;
  }
  .numbertext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #ffffff;
    margin: 0;
  }

  .addResumeText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #4389fa;
    margin-bottom: 0;
    margin-left: 8px;
  }

  .line {
    width: 146px;
    border-bottom: 2px solid #d5e0f3;
    margin-left: 16px;
    margin-right: 16px;
  }
  .title_conatiner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 34px 0px 0px;
  }

  .bottomtext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #606060;
    margin-bottom: 0;
  }

  .error_fields {
    color: #ff4d4f;
    height: 22px;
    margin-left: 40px;
  }
}
