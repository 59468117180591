@import "../../styles/base.scss";

.landingPage_header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4389fa;
  padding: 20px 60px;
  box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);

  .landingPage_header_inner {
    max-width: 1420px;

    // .mobile_heeader {
    //   display: none;

    //   .hambugericon {
    //     display: none;
    //   }
    // }

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;

    .lists {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;

      .listitem {
        margin: 0px 10px;
        color: #ffffff;
      }
    }
  }
}

.uploadFixed {
  padding: 24px 50px;
  // background: #4389fa;
  background: #ffffff;
  // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.1);
  //border-radius: 16px;
  font-weight: 700;
  font-size: 20px;
  // color: #ffff;
  color: #261c4b;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;

  button {
    // background: #ffff;
    background: #4389fa;
    border-radius: 30px;
    font-weight: 400;
    font-size: 18px;
    border: none;
    padding: 6px 20px;
    // color: #4389fa;
    color: #ffffff;
    margin-left: 10px;
    // font-weight: 600;
    cursor: pointer;

    img {
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
}

.mainTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #261c4b;
  margin-bottom: 0;
}

.mainsubtitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #261c4b;
  margin-bottom: 30px;
}

.cardLanding {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  // box-shadow: 0px 9.4968px 16.6194px rgba(38, 80, 189, 0.11);
  border-radius: 12px;
  padding: 20px;
  min-height: 177px;
  border: 1px solid rgba(38, 28, 75, 0.103);

  .landTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 128%;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
    margin: 0;
    text-align: start;
  }

  .landDesc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(38, 28, 75, 0.72);
    text-align: start;
    margin: 0;
    margin-top: 10px;
  }
}

.landingPage {
  width: 100%;
  height: 100%;

  //  body
  .landingBody {
    padding: 2% 10%;
    text-align: center;

    .jobTitle {
      width: 100%;
      height: 330.4px;
      max-width: 610px;
      // max-height: 240px;
      position: relative;
      // z-index: 999;
      background-image: url("../../assets/images/landing/landingimage.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto;
    }

    .landingLine {
      width: 80%;
      height: 157px;
      background-image: url("../../assets/images/landing/landingimgline.svg");
      background-repeat: no-repeat;
      background-size: contain;
      margin: auto;
      margin-top: -120px;
      // margin-bottom: 10px;
    }

    .mainCardsHolter {
      background: #ffffff;
      border-radius: 12px;
      padding: 20px;
      .getStartedButton {
        height: 100%;
        border: none;
        background: #4389fa;
        border-radius: 12px;
        color: #ffffff;
        width: 100%;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .resumeOptimizer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 100px;

      .resonsiveultext {
        display: none;
      }

      img {
        width: 60%;
        height: auto;
      }

      .description {
        width: 40%;
        text-align: left;
        padding-left: 50px;

        .title {
          font-weight: 700;
          font-size: 24px;
          color: #261c4b;
        }

        .subtitle {
          font-weight: 500;
          font-size: 16px;
          color: #261c4b;
          margin: 16px 0px;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          color: rgba(38, 28, 75, 0.72);
          margin: 0px;
          line-height: 20px;
        }
      }
    }

    .companyMatch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 100px;
      .resonsiveultext {
        display: none;
      }
      img {
        width: 60%;
        height: auto;
      }

      .description {
        width: 60%;
        text-align: left;
        padding-left: 50px;

        .title {
          font-weight: 700;
          font-size: 24px;
          color: #261c4b;
        }

        .subtitle {
          font-weight: 500;
          font-size: 16px;
          color: #261c4b;
          margin: 10px 0px;
        }

        p,
        ul {
          font-weight: 400;
          font-size: 16px;
          color: rgba(38, 28, 75, 0.72);
          margin: 0px;
          line-height: 20px;
        }
      }
    }

    .chooseYourPlan {
      text-align: center;
      margin-top: 100px;

      .title {
        font-weight: 700;
        font-size: 24px;
        color: #261c4b;
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        color: rgba(38, 28, 75, 0.8);
        margin: 5px 0px;
      }

      .plans {
        display: flex;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        padding: 40px;

        .card {
          width: 32%;
          background: #ffffff;
          // box-shadow: 0px 3.42816px 30.8535px rgba(0, 0, 0, 0.05);
          border-radius: 10.9701px;
          text-align: left;

          .body {
            padding: 1.5rem 1.2rem;
            min-height: 300px;
            width: 100%;

            .plan {
              background: rgba(0, 153, 0, 0.1);
              border-radius: 13px;
              font-weight: 500;
              font-size: 16px;
              color: #261c4b;
              margin-bottom: 5px;
              width: max-content;
              padding: 0.2rem 4.3rem 0.2rem 1rem;
            }

            .cost {
              font-weight: 600;
              font-size: 32px;
              color: #4389fa;
            }

            .costDescription {
              font-weight: 400;
              font-size: 14.0833px;
              color: #261c4b;
            }

            .devider {
              border: 1.08333px solid rgba(38, 28, 75, 0.12);
              margin: 15px 0px 25px;
            }

            .title {
              font-weight: 500;
              font-size: 15.1667px;
              color: #261c4b;
              margin-bottom: 0.9rem;
            }

            .descriptionItem {
              font-weight: 400;
              font-size: 14.0833px;
              color: #261c4b;
              margin-bottom: 1rem;

              img {
                margin: 0px 5px 3px;
              }
            }
          }

          .footer {
            background: #4389fa;
            border-radius: 0px 0px 16px 16px;
            color: white;
            font-weight: 500;
            font-size: 17.3333px;
            padding: 10px;
            text-align: center;
          }
        }
      }
    }

    .few-more__features {
      .mainImage {
        width: 100%;
        background-image: url("../../assets/images/landing/journey.svg");
        height: 560px;
        margin-top: 100px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .responsove1,
      .responsove2,
      .responsove3,
      .sidemenuSliderwrap,
      .responsove4 {
        display: none;
      }
    }
  }

  // Footer
  .landingFooter {
    background: #4389fa;
    padding: 3% 10%;
    width: 100%;
    margin-bottom: 78px;
    display: flex;
    position: relative;
    justify-content: space-between;

    .sitemap,
    .loginSection,
    .address {
      width: 30%;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.64);
    }

    .address {
      img {
        margin-bottom: 15px;
      }

      .details {
        margin-bottom: 20px;
      }
    }

    .sitemap {
      padding-left: 10%;

      .title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
        color: rgba(255, 255, 255, 0.84);
      }
    }

    .loginSection {
      span {
        margin-left: 5px;
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .landingPage_header {
    .landingPage_header_inner {
      // padding: 20px;
      // justify-content: center;

      // .unnlockIcon,
      // .loginBtn {
      //   display: none;
      // }

      // .mobile_heeader {
      //   display: flex;
      //   justify-content: space-between;
      //   width: 100%;
      //   padding: 0px 0px;

      //   .mobileunnlockIcon {
      //     width: 80px;
      //   }

      //   .loginsigninbutton {
      //     display: none;
      //   }

      //   .hambugericon {
      //     display: block;
      //   }
      // }
    }
  }

  .landingPage {
    .landingBody {
      padding: 24px;

      .resumeOptimizer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 34px;
        flex-direction: column-reverse;

        .dresponseview {
          display: none;
        }

        .resonsiveultext {
          display: block;
          text-align: initial;
        }

        img {
          width: 75%;
          height: auto;
        }

        .description {
          width: 100%;
          padding: 10px;
        }
      }

      .companyMatch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 34px;
        flex-direction: column;

        .dresponseview {
          display: none;
        }

        .resonsiveultext {
          display: block;
          text-align: initial;
        }

        img {
          width: 75%;
          height: auto;
        }

        .description {
          width: 100%;
          padding: 10px;
        }
      }

      .chooseYourPlan {
        margin-top: 34px;

        .plans {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          width: 100%;
          padding: 40px 0;

          .card {
            width: 80%;
            margin-bottom: 20px;

            .body {
              width: 100%;

              .title {
                font-weight: 600;
                margin-left: 8px;
              }

              .descriptionItem {
                font-weight: 500;
                margin-bottom: 18px;
              }

              .devider {
                margin: 8px 0 24px;
              }

              .plan {
                width: max-content;
                padding: 0 60px 0 12px;
              }
            }
          }
        }
      }

      .few-more__features {
        .mainImage {
          background-image: url("../../assets/images/landing/Group1612.svg");
          width: 100%;
          height: 280px;
          background-size: contain;
          margin-top: 64px;
          background-repeat: no-repeat;
        }

        .responsove1 {
          display: block;
          background-image: url("../../assets/images/landing/Group\ 1496.svg");
          width: 75%;
          background-size: contain;
          height: 225px;
          background-repeat: no-repeat;
          margin: auto;
        }

        .responsove2 {
          display: block;
          background-image: url("../../assets/images/landing/Group\ 1497.svg");
          width: 75%;
          background-size: contain;
          height: 225px;
          background-repeat: no-repeat;
          margin: auto;
        }

        .responsove3 {
          display: block;
          background-image: url("../../assets/images/landing/Group\ 1498.svg");
          width: 75%;
          background-size: contain;
          height: 225px;
          background-repeat: no-repeat;
          margin: auto;
        }

        .responsove4 {
          display: block;
          background-image: url("../../assets/images/landing/Group\ 1499.svg");
          width: 75%;
          background-size: contain;
          height: 225px;
          background-repeat: no-repeat;
          margin: auto;
        }
      }
    }

    // Footer
    .landingFooter {
      padding: 3%;
      padding-bottom: 30%;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0px;

      .sitemap,
      .loginSection,
      .address {
        width: 80%;
      }

      .sitemap {
        padding-left: 0%;
        margin: 15px 0px;
      }
    }
  }
}

.ladngCardWraper {
  margin: 28px 0 0;
}

@media only screen and (max-width: 390px) {
  .landingPage {
    .landingFooter {
      padding-bottom: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .openJobCompanyMatchCards {
    margin-top: 15px;
  }
  .landingPage_header {
    padding: 22px 24px;

    .landingPage_header_inner {
      padding: 0;

      .lists {
        display: none;
      }

      .lists1 {
        display: block;
      }

      // .mobile_heeader {
      //   .hambugericon {
      //     .cicleshape {
      //       width: 40px;
      //       height: 40px;
      //       border-radius: 20px;
      //       display: flex;
      //       justify-content: center;
      //       align-items: center;
      //       background: white;

      //       img {
      //         width: 1.1rem;
      //       }
      //     }
      //   }
      // }
    }
  }

  .landingPage {
    .landingBody {
      .jobTitle {
        width: 85%;
        height: 130px;
        background-image: url("../../assets//images/landing/Group\ 1630.svg");
        background-size: 100%;
      }

      .landingLine {
        width: 92%;
        background-image: url("../../assets//images/landing/Group\ 1651.svg");
        background-size: 100%;
        margin-top: 0;
        margin-bottom: 0;
        height: 65px;
      }
    }
  }

  .mainTitle {
    font-size: 1.5rem;
    margin: 1.5rem auto 0.875rem;
    line-height: normal;
  }

  .mainsubtitle {
    font-size: 0.875rem;
    margin-bottom: 2.5rem;
    line-height: normal;
  }

  .cardLanding {
    margin: auto;
    width: 100%;
    margin-bottom: 2rem;
  }

  .uploadFixed {
    flex-direction: column;
    width: 90%;
    margin: 1rem 5%;
    padding: 12px;

    div {
      text-align: left;
      margin-bottom: 12px;
    }

    button {
      text-transform: uppercase;
      padding: 11px 23px;
    }
  }

  .sidemenuSliderwrap {
    display: block;
    position: fixed;
    width: 100%;
    height: 100vh;
    padding: 1.2rem;
    top: 0;
    background-color: #ffffff;
    z-index: 2;

    .closeIcon {
      display: flex;
      justify-content: flex-end;
    }

    .siderbarmenucontanier {
      display: flex;
      flex-direction: column;

      a {
        padding: 1rem 0 0;
        color: #261c4b;
      }

      div {
        padding: 1rem 0 0;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 990px) {
  .openJobCompanyMatchCards {
    margin-top: 15px !important;
  }
}
