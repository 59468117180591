.analisis-container {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;

  .ant-layout-header {
    padding: 0;
    background-color: white;
    display: flex;
    height: 100px;
    align-items: center;
    padding-left: 56px;
    padding-right: 56px;
    justify-content: space-between;
  }
  .ant-layout-header .ant-menu {
    line-height: 42px;
  }
  .analysis_bottom {
    width: 90%;
    margin: auto;
    .analysis_title {
      margin: 40px 0px 40px 20px;
      font-size: 20px;
      font-weight: 600;
    }
    .analysis_bottom_container {
      padding: 30px;
      background: #ffffff;
      border-radius: 24px;
      // box-shadow: 0 8px 32px 0 #e3eeff;
      .ats_tree {
        padding: 10px 20px;
        border-radius: 12px;
        border: 1px solid #dee5ff;
        margin-bottom: 10px;
      }
    }
    .custom_iframe {
      border: none;
    }
  }

  .atsSwitchBtnDiv {
    width: max-content;
    border: 1px solid #999999;
    border-radius: 32px;
    padding: 0 20px;
    cursor: pointer;
  }

  .line {
    width: 0;
    height: auto;
    border-right: 1px solid #999999;
    margin: 0 24px;
  }

  .atsSelectedText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #4389fa;
  }

  .atsNormalText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
  }

  .atsViewDiv {
    background: #ffffff;
    // box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    width: 71%;
    padding: 32px;
    margin-top: 54px;
    .ats_card_container {
      background: rgba(67, 137, 250, 0.1);
      border-radius: 16px;
      padding: 15px;
      margin-bottom: 40px;
      .atsSubDiv {
        border: none;
        background: white;
        border-radius: 8px;
        margin-bottom: 0px;
      }
    }
  }

  .atsSubDiv {
    width: 100%;
    background: #f9fcff;
    border: 1px solid rgba(67, 137, 250, 0.4);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 48px;
  }

  .atsSkillsDiv {
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    .commonSkillsContainer {
      padding: 20px;
      border: 1px solid rgba(67, 137, 250, 0.4);
      border-radius: 12px;
      margin-top: 30px;
      width: 100%;
      .Skills_title {
        font-weight: 600;
        margin-top: -32px;
        background: white;
        width: fit-content;
      }
      .sub_skills_taxanomy {
        padding: 20px;
        border: 1px solid rgba(67, 137, 250, 0.4);
        border-radius: 12px;
        margin-top: 30px;
        width: 100%;
        .sub_taxonomy_name {
          font-weight: 600;
          margin-top: -32px;
          background: white;
          width: fit-content;
        }
      }
      .skills_container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 10px;
      }
      .skill_name {
        margin-right: 5px;
        display: inline;
        span {
          color: rgba(67, 137, 250, 0.4);
        }
      }
    }
  }

  .atsVerticalDisplay {
    display: flex;
    flex-direction: column;
  }

  .atsViewHeaders {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
  }

  .atsViewSubText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
    margin-bottom: 0;
  }
  .atsViewSubNormText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #261c4b;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }

  .borLeftDark {
    border-left: 3px solid rgba(67, 137, 250, 0.4);
  }

  .borLeft {
    color: rgba(67, 137, 250, 0.4);
    margin-right: 24px;
  }

  .bubbletext {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    background: #ffffff;
    // box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
}
