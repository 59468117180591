.card_input_holder {
  padding: 10px;
  position: relative;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #dee5ff;
  border-radius: 8px;
  box-sizing: border-box;
  // height: 50px;
  padding-left: 15px;
  .label {
    font-weight: 500;
    font-size: 12px;
    color: #261c4b;
    background: linear-gradient(0deg, #ffffff, #ffffff), rgba(7, 7, 7, 0.1);
    padding: 0px 5px;
    position: absolute;
    top: -20%;
    left: 15px;
    z-index: 2;
  }
  .StripeElement {
    padding: 3px 0px;
  }
}

.blue_container {
  background: #ebf3ff;
  border-radius: 12px;
  padding: 20px 0px 10px 20px;
  margin: 15px 0px;
  .trial {
    font-size: 18px;
    font-weight: 600;
  }
  .custom_btn.ant-btn {
    background: white !important;
    top: 32%;
    right: 3%;
    font-size: 12px;
  }
}
