.update_experience_container {
  .ps__rail-y {
    display: none;
  }
  .tabs {
    display: flex;
    align-items: center;
    overflow: hidden;
    overflow-x: auto;
    .activeTab {
      padding: 10px;
      border-bottom: 2px solid #4389fa;
      font-weight: 600;
      font-size: 14px;
      color: #4389fa;
      margin-bottom: -1px;
      cursor: pointer;
    }
    .tab {
      padding: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #606060;
      margin-bottom: -1px;
      cursor: pointer;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
  .save-button,
  .delete-button {
    font-size: 16px;
    color: #4389fa;
    background: rgba(67, 137, 250, 0.1);
    border-radius: 10px;
    border: none;
    padding: 7px 12px !important;
    cursor: pointer;
  }
  .delete-button {
    color: #e25151;
    background: rgba(226, 81, 81, 0.1);
    img {
      margin-right: 5px;
      width: 18px;
    }
  }
  .job_description_conatiner {
    margin-bottom: 20px;
    .job_description_label {
      font-weight: 600;
      font-size: 20px;
    }
    .job_description_inner_conatiner {
      position: relative;
      height: auto;

      .fr-sticky-off {
        position: relative;
      }
      .fr-toolbar {
        border: none;
        background: #f9fcff;
      }
      .fr-newline {
        display: none;
      }
      .fr-box.fr-basic .fr-wrapper {
        border: none;
      }
      .fr-second-toolbar {
        display: none;
      }
      .fr-box.fr-basic .fr-element {
        padding-bottom: 0px;
      }
      .fr-toolbar.fr-desktop.fr-top.fr-basic.fr-sticky-off {
        border: 0.5px solid #dee5ff;
        border-radius: 8px 8px 0px 0px;
      }

      .fr-element.fr-view {
        border: 0.5px solid #dee5ff;
        border-radius: 0px 0px 8px 8px;
        margin-top: 5px;
      }
    }

    .Ai_suggestion_header {
      padding: 0px 15px 15px;
      margin: 0px;
    }
    .Ai_suggestion_content {
      padding: 0px 15px 15px;
      margin: 0px 20px 20px;
      margin-top: 0px;
    }
  }
  .skills_titleCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
  }
  .skills_container {
    background: #f9fcff;
    border: 1px solid rgba(67, 137, 250, 0.4);
    border-radius: 8px;
    padding: 15px;
    color: #261c4b;
    margin-bottom: 20px;
    .subtitle {
      font-weight: 600;
      font-size: 14px;
    }
    .skills {
      display: flex;
      flex-wrap: wrap;
      .skill {
        background: #ffffff;
        border: 1px solid lightgray;
        border-radius: 6px;
        padding: 10px;
        margin: 10px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .update_experience_container {
    .actions {
      justify-content: flex-start;
      padding: 15px 0px;
    }
  }
}
