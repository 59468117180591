@import "../../styles/base.scss";

.companies-container {
  width: 100%;
  height: 100%;
  color: rgba(0, 0, 0, 0.85);
  .topbar_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 20px 0px 10px 10px;
    width: 95%;
    background-color: white;
    border-radius: 16px;
    margin: auto;
    margin-bottom: 4px;
    padding: 12px;
    margin-top: 30px;
    .topbar_container_inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .mapSectionTopbar {
        display: flex;
        align-items: center;
        color: #4389fa;
      }
      .companies_matched {
        font-size: 20px;
        font-weight: 600;
        color: #4389fa;
        width: 40%;
      }
      .results_favorites {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: end;
        .results {
          border-right: 1px solid #606060;
          padding-right: 15px;
          cursor: pointer;
        }
        .favrates {
          display: flex;
          align-items: center;
          margin-left: 15px;
          cursor: pointer;
          .favIcon {
            width: 17px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .companies-container-inner {
    .companies-container-left {
      width: 360px;
      margin-right: 42px;
      margin-top: 10px;
      .filteresCount {
        display: flex;
        align-items: center;
        padding: 13px 20px;
        font-size: 18px;
        //border-bottom: 4px solid rgb(233, 241, 255);
        .Filters {
          font-weight: 600;
        }
        .reset {
          color: #4389fa;
          font-weight: 600;
          cursor: pointer;
          margin-left: 20px;
        }
      }
      .leftNav-container-inner {
        padding: 15px 20px;
      }
    }
    .companies-container-right {
      .componies {
        padding-bottom: 15px;
        .mobileSuggestion {
          display: none;
        }
        .companyItem {
          padding: 10px 10px 0px;
        }
        .topTwoCompany1 {
          padding: 10px 10px 0px 0px !important;
        }
        .topTwoCompany2 {
          padding: 15px 10px 0px 0px !important;
        }
        .suggestion_container {
          padding-left: 10px;
        }
      }
    }
  }
  .subscribeAlertContainer {
    background: rgb(67, 137, 250);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    margin-top: 10px;
    border-radius: 16px;
    p {
      font-size: 16px;
      font-weight: 500;
      // background: #4389fa;
      margin-bottom: 20px;
      color: white;
    }
    button {
      background: white;
      color: #4389fa;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      border-radius: 8px;
      font-weight: 600;
    }
  }
  .bottomFixed {
    display: none;
  }
}

.company-container {
  padding: 0px;
  margin-bottom: 56px;
  width: 100%;
  .companySkillsOverflowHidder {
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
    height: 103px;
  }
}

.company_body_right {
  // background: #ffffff;
  // box-shadow: 0px 5px 45px;
  border-radius: 16px;
  padding-bottom: 10px;
  // width: 310px;
  height: 100%;
  // max-height: 83%;
  overflow: scroll;
  overflow-x: auto;
  overflow-y: auto;
  margin-top: 10px;
  .title {
    padding: 15px 0px;
    img {
      margin-right: 20px;
    }
    span {
      font-weight: 500;
      font-size: 24px;
    }
  }
  .title-label {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 2rem;
    color: #261c4b;
  }
  .content {
    background: rgba(67, 137, 250, 0.1);
    border-radius: 24px;
    padding: 15px;
  }
  .default_content {
    color: #606060;
    font-weight: 500;
    font-size: 20px;
    padding: 15px;
    text-align: center;
  }

  .employementSteps {
    .title {
      .subtitle {
        margin-left: 19%;
      }
    }
    .selected_step {
      color: #261c4b;
      .step,
      .label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
      }
      .desc {
        color: rgba(38, 28, 75, 0.84);
        font-size: 14px;
      }
      .nextStepButton {
        background: #4389fa;
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        border: none;
        color: #fefefe;
        margin-top: 15px;
        cursor: pointer;
      }
    }
    .unselected_step {
      color: rgba(38, 28, 75, 0.52);
      font-size: 16px;
    }
    .borderBottom {
      border: 0.5px solid rgba(38, 28, 75, 0.1);
      margin: 20px 0px;
    }
  }
}

.customcomponiesSelectorFilter.ant-select {
  .ant-select-selector {
    border: none !important;
    background: transparent !important;
    font-size: 18px !important;
    color: #4389fa !important;
    input {
      font-weight: 600 !important;
    }
    .ant-select-selection-item {
      img {
        display: none;
      }
    }
  }
}

.companyItemfromDashboard {
  padding: 10px 10px 0px;
  width: 100%;
}

.companyItemfromDashboard,
.companyItem {
  .companyItemboxShadow {
    border-radius: 16px;
    background: white;
    overflow: hidden;
    // boxShadow: 0 8px 32px 0 #e3eeff;
    width: 100%;
    cursor: pointer;
    .descriptionHolder {
      background: white;
      padding: 20px;
      position: relative;
      display: flex;
      align-items: center;
      height: 90px;
      width: 100%;
      .companyLogo {
        cursor: pointer;
        margin-right: 20px;
        width: 15%;
        max-width: 60px;
      }
      .companyName {
        font-weight: 600;
        font-size: 18px;
        text-overflow: ellipsis;
        width: 90%;
      }
      .cityName {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .companyAttributes {
      padding: 0px 20px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .industry {
        margin-left: 16px;
        .industryname {
          white-space: nowrap;
          max-width: 224px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .quickLinks {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .companies-container {
    .topbar_container {
      .topbar_container_inner {
        .results_favorites {
          width: 45%;
          justify-content: flex-start;
        }
      }
    }
    .companies-container-inner {
      .companies-container-left {
      }
      .companies-container-right {
        .componies {
          padding: 0px 0px 15px 0px;
          flex-direction: unset;
          .mobileSuggestion {
            display: block;
          }
          .topTwoCompany1 {
            padding: 15px 0px 0px 0px !important;
          }
          .topTwoCompany2 {
            padding: 15px 0px 0px 0px !important;
          }
          .suggestion_container {
            display: none;
          }
          .ant-col-12 {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .companyItemfromDashboard .companyItemboxShadow .companyAttributes,
  .companyItem .companyItemboxShadow .companyAttributes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 10px;
    .industry {
      .industryname {
        max-width: 160px;
      }
    }
  }
  .companyItemfromDashboard {
    padding: 0 !important;
    .companyItemboxShadow {
      border-radius: 15px;
      .companyAttributes {
        flex-direction: column;
        align-items: flex-start;
        .industry {
          margin-left: 0px;
          margin: 10px 0px;
        }
      }
    }
  }
  .companies-container {
    .topbar_container {
      .topbar_container_inner {
        flex-direction: column;
        align-items: baseline;
        .companies_matched {
          width: 100%;
        }
        .results_favorites {
          width: 100%;
        }
      }
    }
    .companies-container-inner {
      .companies-container-left {
        width: 360px;
        margin-right: 42px;
        .filteresCount {
          display: flex;
          align-items: baseline;
          padding: 20px 0px 20px 0px;
          font-size: 18px;
          flex-direction: column;
          .Filters {
            font-weight: 600;
          }
          .reset {
            color: #4389fa;
            font-weight: 600;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        .leftNav-container-inner {
          padding: 15px;
        }
      }
      .companies-container-right {
        .componies {
          padding-bottom: 15px;
          // flex-direction: column-reverse;
          .suggestion_container {
            padding-left: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .companies-container {
    .topbar_container {
      .topbar_container_inner {
        flex-direction: row;
        align-items: center;

        .companies_matched {
          width: 50%;
          font-size: 14px;
        }
        .results_favorites {
          width: 50%;
          font-size: 14px;
        }
      }
    }
    .companies-container-inner {
      .companies-container-left {
        display: none;
      }
      .companies-container-right {
        max-width: 100%;
        padding: 10px 20px;
      }
    }
    .filtersMask {
      .filteresCount-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background: white;
        img {
          margin: 0px 10px 3px 0px;
        }
        .Filters {
          font-weight: 700;
          font-size: 16px;
          color: #261c4b;
        }
        .reset {
          color: #4389fa;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    .bottomFixed {
      display: block;
      background: #ffffff;
      // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #261c4b;
      font-weight: 500;
      font-size: 14px;
      .filteres {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .filter {
        }
        .count {
          width: 24px;
          height: 24px;
          background: #4389fa;
          border-radius: 12px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 10px;
        }
        .filterIcon {
        }
      }
      .sorting {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .sortBy {
          span {
            color: #4389fa;
            font-weight: 600;
          }
          .arrowdown {
            margin: 0px 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .company_body_right {
    margin-top: 0px;
    padding-bottom: 5px;
  }
  .companies-container {
    .topbar_container {
      .topbar_container_inner {
        flex-direction: column;
        align-items: baseline;
        .companies_matched {
          width: 100%;
          margin-bottom: 5px;
        }
        .results_favorites {
          width: 100%;
        }
      }
    }
    .companies-container-inner {
      .companies-container-right {
      }
    }
  }
  .companyItemfromDashboard {
    .companyItemboxShadow {
      .descriptionHolder {
        padding: 14px 16px 17.5px;
        .companyLogo {
          width: 50px;
        }
        .companyName {
          font-size: 14px;
          margin-right: 0;
          width: 100%;
        }
      }
      .companyAttributes {
        flex-direction: column;
        .industry {
          margin-left: 0px;
          margin: 10px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .companies-container {
    .topbar_container {
      .topbar_container_inner {
        .companies_matched {
          font-size: 12px;
        }
        .results_favorites {
          width: 100%;
          font-size: 12px;
        }
      }
    }
    .companies-container-right {
      padding: 10px !important;
    }
  }
}

.companycard {
  display: flex;
  background: #ffffff;
  width: 100%;
  align-items: center;
  border-radius: 15px;
  // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin: 20px 0;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  .companycardwrap {
    display: flex;
    width: 30%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.companymaincardsection {
  padding: 1.85rem;
  @media screen and (max-width: 600px) {
    padding: 1.5rem;
  }
}
