.personalDashboard_container {
  padding: 30px;
  .headerCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 16px;
    padding: 12px;
  }
  .ant-checkbox-group {
    width: -webkit-fill-available;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    color: #4389fa;
  }

  .faverates {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .resumeVaults {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    padding: 8px 8px 0px;
    justify-content: space-between;

    .resumeVault {
      width: 49.6%;
      border-radius: 16px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      background: #eef3fe;

      .lastupdated {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #606060;
      }

      .documentText {
        background: #ffffff;
        border-radius: 100px;
        padding: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 25px;
        }
      }

      .resume_name {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        /* identical to box height */

        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;

        color: #261c4b;
        width: max-content;
      }

      .controls {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: 50%;

        .white_bg {
          margin: 0px 10px;
          cursor: pointer;
        }
      }
    }
  }

  .skills_wrapper {
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    padding: 10px;
    margin-top: 4px;

    .skillsContainer {
      .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;

        color: #261c4b;
      }

      .blue_bg {
        //background: #eef3fe;
        border-radius: 12px;
        padding: 15px;
        //max-height: 130px;
        overflow: auto;
      }
    }
  }
  .clickCheckJob {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .contentContainerwrap {
    width: 84.5%;
    .contentContainer {
      .selectResume,
      .companyName,
      .jobTitle {
        margin-bottom: 30px;
      }
      .companyName,
      .jobTitle,
      .industry,
      .experience {
        padding-left: 15px;
      }
      .jobDescription {
        margin-top: 30px;
      }
    }
  }

  .tableContainer {
    margin-bottom: 30px;
    // margin-top: 12px;
    // padding-right: 20px;
    // margin-top: 32px;
    // width: 20%;
    gap: 16px;

    table {
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      width: 100%;
      max-height: 500px;
      overflow: auto;
    }

    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */

      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;

      /* Tex/Dark */

      color: #261c4b;
    }

    td,
    th {
      // border: 1px solid #ffffff;
      text-align: left;
      padding: 15px;
    }

    tr {
      // border: 1px solid #f2f4f7;
    }

    tr:nth-child(even) {
      background-color: #fafcff;
    }

    .table_footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 15px;
      border-top: 1px solid #f2f4f7;
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 24px 24px;

      p {
        margin: 0px;
        font-size: 12px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .jobTrackercontentContainerWrap {
    width: 80%;
  }

  .contentContainer {
    // margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 24px;

    .selectResume {
      width: 100%;
      display: flex;
      align-items: center;

      p {
        margin: 0px;
        padding-right: 6px;
        font-weight: 400;
        font-size: 16px;
      }

      .customeselectResume {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid rgb(222, 229, 255);
        border-radius: 8px;
        height: 50px;
        color: white;

        .ant-select-arrow {
          color: rgba(0, 0, 0, 0.25);
        }

        .ant-select-selection-item {
          color: black;
        }
      }
    }
  }

  .add-date-button {
    background: #f9fcff;
    border: 1px solid rgba(67, 137, 250, 0.2);
    border-radius: 10px;
    color: #261c4b;
    width: 100%;
    cursor: pointer;
    padding: 4px 20px;
  }

  .companyName {
    .dropdownArrow {
      opacity: 0.3;
    }

    .dropdownArrowSelected {
      transform: rotate(180deg);
    }
  }

  .companyName:hover {
    background: gainsboro;
    opacity: 0.5;
    cursor: pointer;

    .dropdownArrow {
      opacity: 1;
    }
  }

  .favouriteheadingWraper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 1rem;
  }

  .conatinerwraper {
    margin: 40px 0 0;
  }

  .jobTrakerContainer {
    display: flex;
  }
}

.faviroutecompanylistwrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #f5f5f5 !important;
}

@media (min-width: 575px) and (max-width: 768px) {
  .faviroutecompanylistwrap {
    // flex-direction: ;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .personalDashboard_container {
    padding: 24px;
    .headerCard {
      margin-bottom: 16px;
    }
    .tableContainer {
      overflow: scroll;
      width: 100%;
      margin-bottom: 24px;
      margin-top: 16px;
      padding-right: 0;
      gap: 0;
    }

    .favouriteheadingWraper {
      padding: 16px 12px;
      margin-bottom: 0;
    }

    .title {
      font-size: 15px;
      line-height: normal;
    }

    .resumeVaults {
      flex-direction: column;
      margin-top: 16px;
      padding: 1rem 1rem 0;

      .resumeVault {
        width: inherit;
        margin-bottom: 12px;
        .resume_name {
          width: 100%;
        }
      }
    }

    .skills_wrapper {
      margin-top: 16px;
    }

    .jobTrakerContainer {
      flex-direction: column;
    }

    .jobTrackercontentContainerWrap {
      width: 100%;
    }

    .contentContainer {
      margin-top: 0;
      padding: 1rem;
    }
    .clickCheckJob {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .contentContainerwrap {
      width: 100%;
      .contentContainer {
        .selectResume,
        .companyName,
        .jobTitle {
          margin-bottom: 0px;
        }
        .companyName,
        .jobTitle,
        .industry,
        .experience {
          padding-left: 10px;
        }
        .jobDescription {
          margin-top: 0px;
        }
      }
    }
  }
}

@media screen and(max-width:575px) {
  .faviroutecompanylistwrap {
    // flex-direction: ;
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .personalDashboard_container {
    padding: 10px !important;
    .headerCard {
      margin-bottom: 16px;
    }
    .tableContainer {
      overflow: scroll;
      width: 100%;
      margin-bottom: 24px;
      margin-top: 16px;
      padding-right: 0;
      gap: 0;
    }

    .favouriteheadingWraper {
      padding: 16px 12px;
      margin-bottom: 0;
    }

    .title {
      font-size: 15px;
      line-height: normal;
    }

    .resumeVaults {
      flex-direction: column;
      margin-top: 16px;
      padding: 1rem 1rem 0;

      .resumeVault {
        width: inherit;
        margin-bottom: 12px;
        .resume_name {
          width: 100%;
        }
      }
    }

    .skills_wrapper {
      margin-top: 16px;
    }

    .jobTrakerContainer {
      flex-direction: column;
    }

    .jobTrackercontentContainerWrap {
      width: 100%;
    }

    .contentContainer {
      margin-top: 0;
      padding: 1rem;
    }
    .clickCheckJob {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .contentContainerwrap {
      width: 100%;
      .contentContainer {
        .selectResume,
        .companyName,
        .jobTitle {
          margin-bottom: 0px;
        }
        .companyName,
        .jobTitle,
        .industry,
        .experience {
          padding-left: 0px;
        }
        .jobDescription {
          margin-top: 0px;
        }
      }
    }
  }
}
