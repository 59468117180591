.topbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  padding: 20px 0px 10px 10px;
  width: 95%;
  background-color: white;
  border-radius: 16px;
  margin: auto;
  margin-bottom: 14px;
  padding: 12px;
  margin-top: 30px;
  .topbar_container_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .mapSectionTopbar {
      display: flex;
      align-items: center;
      color: #4389fa;
    }
    .companies_matched {
      font-size: 20px;
      font-weight: 600;
      color: #4389fa;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .results_favorites {
      width: 33%;
      display: flex;
      align-items: center;
      justify-content: end;
      .results {
        border-right: 1px solid #606060;
        padding-right: 15px;
        cursor: pointer;
      }
      .favrates {
        display: flex;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;
        .favIcon {
          width: 17px;
          margin-right: 5px;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .topbar_container_inner {
      display: block;
      .companies_matched {
        margin-bottom: 15px;
        white-space: nowrap;
      }
      .results_favorites {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 670px) {
    .topbar_container_inner {
      .companies_matched {
        font-size: 12px;
        .Current_Resume {
          display: none;
        }
      }
    }
  }
}
