.landng-footer__Section {
  background: #4389fa;
  width: 100%;
  padding: 45px 10%;
  color: #fff;
  position: relative;
  z-index: 100;

  .landing-footer__flexcontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;

    .footerlogo-address__container {
      // flex: 1;
      //width: 275px;
    }

    .footer-lading__date {
      font-weight: 400;
      font-size: 12px;
    }

    .addressFont {
      font-size: 14px;
      opacity: 0.64;
      margin-bottom: 0;
    }

    .date_section {
      margin-top: 16px;
    }

    .footer-sitemap__Section {
      // flex: 1;
    }

    .title {
      font-size: 1.5rem;
      letter-spacing: 0.0168em;
      font-feature-settings: "tnum" on, "lnum" on;
      margin-bottom: 2rem;
      margin-top: 12px;
    }

    .subtitle {
      opacity: 0.64;
      margin: 1rem 0 0;
      letter-spacing: 0.0168em;
      font-feature-settings: "tnum" on, "lnum" on;
    }
  }
  .footer-login__section {
    div {
      color: rgba(255, 255, 255, 0.64);
      span {
        opacity: 1;
        color: #fff;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .landng-footer__Section {
    padding: 1.5rem 1.5rem 204px;

    .landing-footer__flexcontainer {
      flex-direction: column;

      .date_section {
        margin-top: 40px;
      }
      .title {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
      }
      .footer-login__section {
        margin-top: 4rem;
      }
    }
  }
}
