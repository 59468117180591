.mjr_bottomContainer {
  background: #ffffff;
  border-radius: 24px;
  padding: 30px;
  // box-shadow: 0 8px 32px 0 #e3eeff;
  margin-top: 50px;
  width: 90%;
  .bottom_header {
    display: flex;
    align-items: center;
    .text_holder {
      font-weight: 600;
      font-size: 20px;
      color: #4389fa;
    }
    .rating_star.ant-rate {
      font-size: 18px;
    }
    img {
      margin-right: 15px;
    }
  }
  .bottom_body{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
