$text: #261c4b;
$blue: #4389fa;
$lightblue: #9bc2ff;

.custom_confirm_model,
.custom_addSkillsModal,
.confirmationModal,
.custom_addSkillsModal_enlarged {
  width: 100%;
  .model_body {
    width: 100%;
    display: flex;
    align-items: center;
    .img_holder {
      width: 50%;
      padding-right: 10px;
      img {
        width: 100%;
      }
    }
    .content_holder {
      width: 50%;
      .input_holder {
        margin-bottom: 20px;
      }
      .title {
        width: 100%;
        font-size: 18px;
        color: $text;
        font-weight: 600;
      }
      .subTitle {
        color: #261c4b;
        font-weight: 400;
        font-size: 14px;
        opacity: 0.64;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.45);
      }
      .suggestedSkills,
      .selecteddSkills {
        color: #000000;

        .title {
          font-weight: 600;
          font-size: 16px;
          margin: 15px 0px;
        }
        .skills {
          // background: rgb(233, 241, 255);
          border: 1px solid rgba(67, 137, 250, 0.4);
          border-radius: 8px;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          max-height: 200px;
          overflow: auto;
          .skill {
            padding: 5px 10px;
            background: #ffffff;
            border: 1px solid lightgray;
            border-radius: 8px;
            margin: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            p {
              margin-bottom: 2px;
            }
            span {
              font-size: 22px;
              padding-left: 10px;
            }
          }
        }
        .save-button {
          font-size: 16px;
          color: #4389fa;
          background: rgba(67, 137, 250, 0.1);
          border-radius: 10px;
          border: none;
          padding: 7px 12px !important;
          cursor: pointer;
          margin: 10px;
        }
      }
      .selecteddSkills {
        .skills {
          .skill {
            padding-bottom: 0px;
          }
          span {
            transform: rotate(45deg);
            margin-bottom: 7px;
          }
        }
      }
      .sub_txt {
        color: $text;
        margin: 10px 0px 30px;
      }
      .buttons_container {
        display: flex;
        .custom_btn {
          border-radius: 12px;
          min-width: 150px;
          height: 45px;
        }
      }
    }
  }
  &.ant-modal {
    .ant-modal-content {
      background: rgb(233, 241, 255);
      border-radius: 24px;
    }
    .ant-modal-header {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  }
}

.custom_addSkillsModal,
.custom_addSkillsModal_enlarged,
.confirmationModal {
  .buttons_container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    padding-top: 15px;
    .custom_btn {
      border-radius: 12px;
      min-width: 150px;
      height: 45px;
    }
  }
  &.ant-modal {
    .ant-modal-content {
      background: white;
      border-radius: 14px;
    }
    .ant-modal-header {
      display: block;
    }
    .ant-modal-close {
      display: block;
    }
  }
}

.custom_addSkillsModal_enlarged {
  &.ant-modal {
    top: 20px;
  }
}

.ant-select-dropdown {
  margin-top: -10px !important;
  // box-shadow: none !important;
}

.subscription_model {
  // position: relative;
  // .model_body {
  //   .company_inputs {
  //     padding: 10px;
  //     border: 1px solid rgba(67, 137, 250, 0.4);
  //     background: "white";
  //     border-radius: 5px;
  //   }
  //   .img_holder {
  //     position: absolute;
  //     top: -25px;
  //     left: -30px;
  //   }

  //   .header {
  //     text-align: center;
  //     display: flex;
  //     width: 100%;
  //     justify-content: center;
  //     .text_holder {
  //       width: 60%;
  //       .title {
  //         margin-top: 20px;
  //         width: 100%;
  //         font-size: 18px;
  //         color: $text;
  //         font-weight: 600;
  //       }
  //       .sub_txt {
  //         color: $text;
  //         margin-top: 15px;
  //       }
  //     }
  //   }
  //   .model_body {
  //     padding: 10px;
  //     width: 100%;
  //     .card_holder {
  //       width: 100%;
  //       display: flex;
  //       justify-content: space-around;
  //       flex-wrap: wrap;
  //     }
  //   }
  // }
  &.ant-modal {
    .ant-modal-content {
      background: white;
      border-radius: 24px;
    }
    .ant-modal-header {
      display: none;
    }
  }

  .plansContainer {
    width: 100%;
    height: 100%;
    padding-bottom: 12px;

    .backBtn{
      padding: 15px;
      position: absolute;
      top: 2%;
      left: 2%;
      cursor: pointer;
    }

    .subheader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 12px;

      .subsTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #261c4b;
        margin-bottom: 16px;
      }

      .subsDesc {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #606060;
      }
    }
  }
}

.change_payment_model {
  .custom_model_content {
    width: 100%;
  }
  .ant-modal-body {
    padding: 60px;
  }
  width: 100%;
  .title {
    width: 100%;
    font-size: 18px;
    color: $text;
    font-weight: 600;
  }
  .model_body {
    .billing_container {
      background: #ebf3ff;
      border-radius: 12px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      position: relative;
      .left_side {
        .title {
          width: 100%;
          font-size: 18px;
          color: $text;
          font-weight: 600;
          cursor: pointer;
        }
        .discount {
          font-weight: 500;
          font-size: 12px;
          color: #4389fa;
        }
        .arrow_img {
          display: inline;
          margin-left: 10px;
        }
      }
      .right_side {
        .amount {
          font-weight: 600;
          font-size: 34px;
          color: #4389fa;
        }
        .per_mnth {
          font-weight: 500;
          font-size: 12px;
          color: $text;
        }
      }
    }
    .propmoinput_holder {
      margin-top: 20px;
      .Custnom_input_withbtn.ant-input-group-wrapper {
        .ant-input-search.ant-input-group.ant-input-affix-wrapper:not(:last-child) {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        input {
          height: 50px;
        }
        button {
          height: 50px;
          width: 150px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
    .inputs_container {
      padding: 30px;
      background: white;
      border-radius: 12px;
      margin: 30px 0px;
      .payment_title {
        width: 100%;
        font-size: 18px;
        color: $text;
        font-weight: 600;
      }
      .input_container {
        margin-top: 20px;
      }
      .displ_singleline {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .input_container2 {
          width: 48%;
          margin-top: 20px;
          position: relative;
        }
      }
      .check_container {
        margin-top: 20px;
      }
    }
  }
  .model_footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .stripe {
      font-weight: 500;
      font-size: 12px;
      color: #261c4b;
    }
    .pay_btn {
      border-radius: 12px;
      padding: 0px;
      width: 170px;
      height: 45px;
    }
  }
  &.ant-modal {
    .ant-modal-content {
      background: rgb(233, 241, 255);
      border-radius: 24px;
    }
    .ant-modal-header {
      display: none;
    }
  }
}

.billingDrop_container {
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 5px;
  width: 35%;
  .arrow_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .arrow-up {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 15px solid white;
      margin-right: 25%;
    }
  }
  .body_items {
    background-color: white;
    border-radius: 24px;
    padding: 20px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    p {
      span {
        font-size: 12px;
        color: #4389fa;
        margin-left: 20px;
      }
    }
    .signature_holder {
      width: 70%;
      background: #ebf3ff;
      border-radius: 8px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .cvv {
        font-weight: 500;
        font-size: 12px;
        background: #ffffff;
        border-radius: 8px;
        padding: 10px;
      }
    }
  }
}

.atsViewModal {
  width: 60vw !important;
  padding-bottom: 20px;
  .model_body {
    .content_holder {
      .atsTitletxt {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #4389fa;
        margin-bottom: 0;
        margin-left: 16px;
      }

      .atsSubtxt {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: rgba(38, 28, 75, 0.8);
        margin-bottom: 0;
        margin-left: 32px;
        margin-top: 8px;
      }
    }
  }
  &.ant-modal {
    .ant-modal-body {
      padding-top: 0px;
    }
    .ant-modal-content {
      background: rgb(233, 241, 255);
      border-radius: 24px;
      padding: 20px;
    }
  }
}

/* Styles for phones (up to 767px) */
@media only screen and (max-width: 767px) {
  .subscription_model .plansContainer .backBtn{
    top: 1%;
  }
}

/* Styles for tablets (768px to 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .subscription_model .plansContainer .backBtn{
    top: 1%;
  }

  .atsViewModal {
    width: 80vw !important;
  }
}
