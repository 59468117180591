@import "../../styles/base.scss";

.blogs-container {
  width: 100vw;
  height: 100%;

  .ant-layout-header {
    padding: 0;
    background-color: white;
    display: flex;
    height: 100px;
    align-items: center;
    padding-left: 56px;
    padding-right: 56px;
    justify-content: space-between;
  }
  .ant-layout-header .ant-menu {
    line-height: 42px;
  }

  .ant-layout-content {
    background-color: #e3eeff;
    padding: 48px;
  }

  @media screen and (max-width: 1440px) {
    width: 100vw;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
  }

  @media screen and (min-width: 1920px) {
    width: 1440px;
  }
}
