@mixin theflexstyle($direction, $justifyContent, $alignItems, $wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-wrap: $wrap;
}
.card__conatiner {
  cursor: pointer;
}

.card__conatiner,
.details_card__conatiner {
  background: #ffffff;
  box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 0 24px;
  margin-bottom: 16px;
  .card__header {
    @include theflexstyle(row, space-between, inherit, nowrap);
    border-bottom: 0.5px solid #e7e7e7;
    padding: 20px 0;
    .card_image__name {
      @include theflexstyle(row, space-between, center, nowrap);
      .companyLogo {
        cursor: pointer;
        margin-right: 20px;
        width: 15%;
        max-width: 60px;
        width: fit-content;
      }

      .card__name {
        .card_company__name {
          font-size: 18px;
          font-weight: 600;
        }
        .card_company__name,
        .card_company__companyraw {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  .card__body {
    padding: 24px 0;
    display: flex;
    width: 100%;
    .card__body_right {
      width: 50%;
      .card__bodysection {
        @include theflexstyle(row, flex-start, center, nowrap);
        // gap: 20px;
        div {
          // gap: 20px;
          width: 100%;
          min-width: 0px;
          padding-bottom: 16px;
        }
        .card__bodysection_col {
          width: 50%;
          padding-right: 10px;
          padding-bottom: 10px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          word-break: break-all;
          .card_body__heading {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #393939;
            margin: 0;
            width: 100%;
          }
          .card-body__subheading {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.01em;
            color: #606060;
            width: fit-content;
            max-width: 90%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
    .card_body_left {
      width: 50%;

      .card_body__heading {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #393939;
        margin: 0;
      }
      .card-body__subheading {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #606060;
        display: block;
        width: 100%;
        margin-bottom: 0;
        max-height: 200px;
        overflow: auto;
      }
    }
  }
}

.details_card__conatiner {
  margin-left: 15px;
  .card__body {
    flex-direction: column;
    .card__body_right,
    .card_body_left {
      width: 100%;
    }
    .card__body_right {
      .card-body__subheading {
        width: fit-content;
        max-width: 90%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
        white-space: pre-wrap !important; /* let the text wrap preserving spaces */
      }
    }
  }
}

.OpenJobComapnies_container {
  .bottomFixed {
    display: none;
  }
  .right-Cards-container {
    padding-right: 0 !important;
  }
  @media only screen and (max-width: 750px) {
    .right-Cards-container {
      max-width: 100%;
      padding-left: 0 !important;
    }
    .left-filter-container {
      display: none;
    }
    .filtersMask {
      position: fixed;
      bottom: 0;
      left: 20px;
      right: 20px;
      z-index: 100;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      border-radius: 8px;
      .filteresCount-mobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        background: white;
        border-radius: 8px;
        img {
          margin: 0px 10px 3px 0px;
        }
        .Filters {
          font-weight: 700;
          font-size: 16px;
          color: #261c4b;
        }
        .reset {
          color: #4389fa;
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    .bottomFixed {
      display: block;
      background: #ffffff;
      // box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.1);
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #261c4b;
      font-weight: 500;
      font-size: 14px;
      z-index: 200;
      .filteres {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .filter {
        }
        .count {
          width: 24px;
          height: 24px;
          background: #4389fa;
          border-radius: 12px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 10px;
        }
        .filterIcon {
        }
      }
    }
  }
}

.OpenJobComapnies_Details_container {
  .OpenJobComapnies_Details_container_inner {
    margin: auto;
    width: 95%;
    justify-content: space-between;
    .left-componies-list-container {
      padding-left: 0px;
      max-height: 140vh;
      overflow: auto;
    }
    .right-details-container {
      padding-right: 0;
    }
  }
  @media only screen and (max-width: 750px) {
    .details_card__conatiner {
      margin-left: 0;
    }
    .OpenJobComapnies_Details_container_inner {
      flex-direction: column-reverse;
      margin-left: 10px !important;
      margin-right: 10px !important;
      width: 97%;
      .left-componies-list-container {
        max-width: 100%;
        max-height: 30vh;
      }
      .right-details-container {
        max-width: 100%;
      }
    }
  }
}
