.mjr_topcontainer {
  padding: 0px 15px;
  .job_title {
    font-weight: 600;
    font-size: 20px;
    color: #4389fa;
  }
  .details_container {
    margin-top: 20px;
  }
  .viewall_btn.ant-btn {
    padding: 0px;
    margin-top: 10px;
  }
}
